import { Injectable, signal, Signal } from '@angular/core';

export enum RECORDING_STATE {
  NONE = 'none',
  STARTING = 'starting',
  IN_PROGRESS = 'in_progress',
  PAUSED = 'paused',
  ENDED = 'ended',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class HealthscribeRecorderService {
  appointmentRecordingId: string | undefined;

  #recordingState = signal(RECORDING_STATE.NONE);

  get recordingState(): Signal<RECORDING_STATE> {
    return this.#recordingState;
  }

  get currentDurationMs(): Signal<number> {
    console.log('getting current duration of recording');
    return signal(0);
  }

  async startRecording(appointmentId: string): Promise<void> {
    console.log(`start recording: ${appointmentId}`);
  }

  async pauseRecording(): Promise<void> {
    console.log('pause recording');
  }

  async resumeRecording(): Promise<void> {
    console.log('resume recording');
  }

  async endRecording(): Promise<string> {
    console.log('end recording');
    return this.appointmentRecordingId ?? 'placeholder';
  }
}
