import { Component, Input, ViewChild } from '@angular/core';
import {
  HealthscribeRecorderService,
  RECORDING_STATE,
} from '../healthscribe-recorder-service';
import {
  RecordingControlComponent,
  RENDER_MODE,
} from '../recording-control/recording-control.component';

@Component({
  selector: 'omg-recording-manager',
  templateUrl: './recording-manager.component.html',
})
export class RecordingManagerComponent {
  @Input() mode: RENDER_MODE;
  @Input() appointmentId: string;

  @ViewChild('recordingControl') recordingControl: RecordingControlComponent;

  constructor(
    private healthscribeRecorderService: HealthscribeRecorderService,
  ) {}

  get recordingState(): RECORDING_STATE {
    return this.healthscribeRecorderService.recordingState();
  }

  get currentDurationMs(): number {
    return this.healthscribeRecorderService.currentDurationMs();
  }

  pauseRecording() {
    this.healthscribeRecorderService.pauseRecording();
  }

  record() {
    if (
      this.healthscribeRecorderService.recordingState() === RECORDING_STATE.NONE
    ) {
      this.healthscribeRecorderService.startRecording(this.appointmentId);
    } else if (!this.healthscribeRecorderService.appointmentRecordingId) {
      console.error(
        'appointment recording ID is undefined when we expected it to be defined',
      );
      this.healthscribeRecorderService.startRecording(this.appointmentId);
    } else {
      this.healthscribeRecorderService.resumeRecording();
    }
  }

  endRecording() {
    this.healthscribeRecorderService.endRecording();
  }
}
